import Row from "./Row";
import BoardInterface from "../../interfaces/BoardInterface";
import { maxRows } from "../../constants/Constants";
import './Board.css'
import { range } from "../../App";
import Button from "../ui/Button";
import TileInterface from "../../interfaces/TileInterface";
import { match } from "../../service/matching_service";

interface PropsInterface {
    board: BoardInterface;
    input: string;
    won: boolean;
}

export default function Board(props: PropsInterface) {
    const board = props.board;
    const input = props.input;
    const emptyRows = maxRows - (board.guesses.length + 1);
    const iterable = range(emptyRows);

    return (
        <div className="game">
            <h1 className="heading">Randordle</h1>

            <div className="board">
                {props.won && (
                    <div className="flex-col">
                        <span className="subheader">the word was:</span>
                        <Button text={board.word} />
                        <span className="again">reload to play again</span>
                    </div>
                )}

                {board.guesses.map((guess: string, key: number) => {
                    const tiles = calculateTiles(guess, board.word, true);

                    return <Row key={key} tiles={tiles} />;
                })}

                {board.guesses.length < maxRows && (
                    <Row key={maxRows} tiles={calculateTiles(input, board.word, false)} />
                )}

                {iterable.map((_: number, key: number) => {
                    return <Row key={key} tiles={calculateEmptyTiles(board.letters)} />;
                })}
            </div>
        </div>
    )
}

function calculateTiles(guess: string, word: string, revealed: boolean): TileInterface[] {
    let tiles: TileInterface[] = [];

    if (!revealed) {
        guess.split('').forEach(character => {
            tiles.push({
                revealed: false,
                character: character
            })
        })

        tiles.push(...calculateEmptyTiles(word.length - guess.length))

        return tiles
    }

    let results = match(word, guess)

    results.forEach(item => {
        tiles.push({
            character: item.letter,
            state: item.status,
            revealed: revealed,
        })
    })

    return tiles;
}

function calculateEmptyTiles(letters: number): TileInterface[] {
    let tiles: TileInterface[] = [];

    for (let i = 0; i < letters; i++) {
        tiles.push({

            revealed: false
        })
    }

    return tiles;
}