import { State } from "../../constants/Constants";
import TileInterface from "../../interfaces/TileInterface";

interface PropsInterface {
    tile: TileInterface;
}

export default function Tile(props: PropsInterface) {
    if (!props.tile.revealed) {
        return <div className="tile">
            <span>{props.tile.character}</span>
        </div>
    }

    return (
        <div className="tile" style={{ backgroundColor: props.tile.state }}>
            <span>{props.tile.character}</span>
        </div>
    )
}