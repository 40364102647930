import { State } from "../constants/Constants";

type LetterMatch = {
    letter: string;
    status: State;
};

export function match(word: string, guess: string): LetterMatch[] {
    if (word.length !== guess.length) {
        throw new Error("Word and guess must have the same length");
    }

    const result: LetterMatch[] = [];
    const wordLetters = word.toLocaleLowerCase().split('');
    const guessLetters = guess.toLocaleLowerCase().split('');

    const wordLetterCount: { [key: string]: number } = {};

    guessLetters.forEach((letter, i) => {
        if (letter === wordLetters[i]) {
            result.push({ letter, status: State.CORRECT });
        } else {
            result.push({ letter, status: State.ABSENT });
            wordLetterCount[wordLetters[i]] = (wordLetterCount[wordLetters[i]] || 0) + 1;
        }
    });

    result.forEach((entry, i) => {
        if (entry.status === State.ABSENT && wordLetterCount[entry.letter]) {
            result[i].status = State.PRESENT;
            wordLetterCount[entry.letter]--;
        }
    });

    return result;
}