import React from 'react';
import BoardInterface from "./interfaces/BoardInterface";
import Board from "./components/game/Board";
import { allowedLetters, maxRows } from "./constants/Constants";

type State = {
    locked: boolean;
    board: BoardInterface;
    input: string;
    won: boolean;
}

export default class App extends React.Component<any, State> {

    constructor(props: any) {
        super(props);

        this.state = {
            locked: false,
            board: {
                id: this.generateString(8),
                word: this.generateString(5),
                guesses: [],
                letters: 5
            },
            input: '',
            won: false
        }
    }

    generateString = (length: number) => {
        let result = '';
        let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
        let charactersLength = characters.length;

        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }

        return result;
    }

    isGameWonOrLost = (board: BoardInterface, input: string) => {
        const lastGuess = board.guesses.at(board.guesses.length - 1);

        if (lastGuess === undefined) {
            return false;
        }

        return board.guesses.length >= maxRows
            || input.toLowerCase() === board.word.toLowerCase();
    }

    // @ts-ignore
    handleInput = (event: KeyboardEvent) => {
        const input = this.state.input;
        const locked = this.state.locked;
        const board = this.state.board;

        if (locked) {
            return;
        }

        let letter = event.key.toLowerCase();

        if (letter === 'backspace') {
            this.setState({ input: input.substring(0, input.length - 1) })
            return;
        }

        if (letter === 'enter') {
            this.handleSubmitGuess(input);
            return;
        }

        if (!allowedLetters.includes(letter)) {
            return;
        }

        let newInput = input + letter

        if (newInput.length > board.letters) {
            return;
        }

        this.setState({ input: newInput })
    }

    handleSubmitGuess = (guess: string) => {
        const board = this.state.board;

        if (guess.length < board.letters) {
            return;
        }

        board.guesses.push(guess);

        this.setState({ input: '', locked: false })

        if (this.isGameWonOrLost(board, guess)) {
            this.setState({ won: true })
        }
    }

    componentDidMount() {
        window.addEventListener('keydown', this.handleInput);
    }

    render() {
        return (
            <div className="app">
                <input type="text" id="hidden_input" className="hidden-input" autoFocus />
                <Board board={this.state.board} input={this.state.input} won={this.state.won} />
            </div>
        );
    }
}

export function range(size: number, startAt = 0) {
    if (size < 0) {
        size = 0;
    }

    // @ts-ignore
    return [...Array(size).keys()].map(i => i + startAt);
}
